import About from '../components/About';
import Services from '../components/Services';

function Main() {
  return (
    <>
      <main>
        <Services />
        <About />
      </main>
    </>
  );
}
export default Main;
